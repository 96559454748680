//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'BottomBar',
  props: {
    heading: {
      type: String,
      required: false,
    },
    sliderValue: {
      type: Number,
      required: true,
    },
    sliderStep: {
      type: Number,
      required: true,
    },
    locationsAreReady: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleChange(newValue) {
      this.$emit('sliderChanged', Number(newValue));
    },
  },
  $trs: {
    progress: `{progress, number, percent}`,
    jumpToPositionInBook: 'Jump to position in book',
    preparingSlider: 'Preparing slider',
  },
};

