//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'SettingsButton',
  $trs: {
    toggleSettingsSideBar: 'Toggle settings',
  },
};

