//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'PreviousButton',
  props: {
    // TODO - refactor to use themes properly
    color: {
      type: String,
      required: true,
      validator(val) {
        return ['black', 'white'].includes(val);
      },
    },
  },
  $trs: {
    goToPreviousPage: 'Go to previous page',
  },
};

