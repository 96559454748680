//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'LoadingScreen',
  computed: {
    backgroundStyle() {
      return {
        backgroundColor: this.$themeTokens.surface,
      };
    },
  },
  $trs: {
    loadingBook: 'Loading book',
  },
};

