//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UiAlert from 'kolibri-design-system/lib/keen/UiAlert';

export default {
  name: 'LoadingError',
  components: {
    UiAlert,
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  $trs: {
    couldNotLoadThisBook: 'Could not load this book',
  },
};

