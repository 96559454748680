//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'SearchButton',
  $trs: {
    toggleSearchSideBar: 'Toggle search',
  },
};

