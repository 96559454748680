//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'TocButton',
  $trs: {
    toggleTocSideBar: 'Toggle table of contents',
  },
};

