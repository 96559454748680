//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import SideBar from './SideBar';
import TableOfContentsSection from './TableOfContentsSection';

export default {
  name: 'TableOfContentsSideBar',
  components: {
    SideBar,
    TableOfContentsSection,
  },
  props: {
    toc: {
      type: Array,
      required: true,
    },
    currentSection: {
      type: Object,
      required: false,
    },
  },
  methods: {
    emitTocNavigation(section) {
      this.$emit('tocNavigation', section);
    },
  },
};

